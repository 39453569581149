import React from 'react'
import { FaArrowCircleRight } from 'react-icons/fa'

export default [
  {
    id: '1',
    icon: <FaArrowCircleRight />,
    PointPara: '⟶',
    ParaOne:
      'It’s no secret procurement has come a long way from its initial roots as a tactical function of acquiring goods and services on an ‘as-needed’ basis, to now being recognized as a critically strategic function streamlining the business and delivering bottom-line savings to increase company margins. That doesn’t mean the value added by Procurement is sustainable, as the function is challenged at times to align its goals and activities with the broader stakeholder requirements. As Procurement’s legacy operating models can fall behind in attempting to serve the emerging industry needs and challenges, we must opt for digital transformation to support the businesses we serve in a better and future-proof way.',
    ParaTwo:
      'With Raindrop’s spend management platform, customers realize rapid value attainment to support their entire sourcing digitization needs, ranging from planning, sourcing, contracting, supplier management, and far more. Common amongst Procurement leaders’ top mutual goals for sourcing digitization included process automation, reporting data quality, cost savings, streamlined compliance, collaboration, etc. Raindrop frictionless and integrated platform architecture was designed from the ground up to provide deeper capabilities through Artificial Intelligence (AI) and Machine learning (ML), offering rapid insights on external spend, all the way from planning through to operation. This enables to reduce the various digital point solution systems footprints, while actively streamlining processes and recouping hundreds of thousands of dollars in operational and capital expenses.',
    ParaThree: 'Today, the top three compelling needs of all CPOs and their procurement teams are:',
    HeadingOnePointOne: 'Improved insights and strategies through spend intelligence',
    ParaFour:
      'With Raindrop, move up the value chain by shifting the CPO’s Procurement organization to a “predictive sourcing” model by knowing where and how funds leave the company. Gain immediate spend visibility and advanced spend intelligence to deliver on cost reduction and supplier cost efficiency strategies, courtesy of Raindrop’s native Machine Learning and Artificial Intelligence technologies. Optimize these spend categories and begin generating very real cash from operations, in turn, delivering increased margins back to your company.',
    HeadingOnePointTwo: 'Enhanced process excellence, leading to greater organizational efficiency and effectiveness',
    ParaFive:
      'One key barrier for procurement to coordinate effectively and efficiently is due to the spend data being fragmented over multiple systems, geographies, and internal organizations. With this approach, Procurement and Finance are not able to quantify or look at corporate spend in a holistic manner, resulting in spend and data leakage. To solve this critical issue, Raindrop provides a cohesive platform, including powerful Analytics and Planning to remove data silos across multiple corporate entities. Raindrop’s frictionless integration and user experience ensure Procurement and Finance get a unified view of spend and drive specific actions to maximize the value of your investments. Raindrop ties all the technologies for effective spend management into an easy-to-use platform that enables you to do your business your way. Raindrop’s unified system capabilities deliver spend forecasts, and financial analysis, simplify annual budget planning and help identify risks in advance, which are critical components for both Procurement and Finance.',
    HeadingOnePointThree: 'Better assurance of supply with improved risk mitigation',
    ParaSix:
      'In recent times, major issues of port congestion, labor shortages, and sky-rocketing demand have created havoc in the global supply chain. As supply chain management has been growing increasingly complex and critical to each organization, Supply Chain leaders have taken on a higher degree of criticality by adopting technology-driven solutions to maximize fully-integrated spend transparency. Enterprise Spend Management technologies such as Raindrop deliver increased efficiencies, reduced cycle times, enhanced internal and external collaboration, materials consumption visibility, and higher transparency across all contractual obligations. Raindrop’s cloud-based intelligent, scalable, and secured solution provides real-time visibility in the supply chain for proactive decision-making to manage products, people, and processes in a better and digitized way. With Raindrop stay on top with your supplier’s compliance and create a streamlined governance process to identify or mitigate any risks.',
    HeadingFour: 'Unlock agility with Raindrop’s Digital Procurement Transformation',
    ParaSeven:
      'Businesses can drive value instantly by leveraging digital operating models. Raindrop supports its customers by making their business and processes proactive, resilient, profitable, and future-proof. It is important to act now and choose Raindrop’s single pane, quickly up-and-running, AI-enabled digital solutions to accelerate your sourcing and procurement journey. Digitization, artificial intelligence (AI), and machine learning (ML) are replacing and vastly improving the legacy tactical and operational functional procurement work such as going to market with an RF(x)s, spend governance, contract management, and spend analytics. Today, Procurement organizations must focus on more strategic solutions and proactively manage categories, risk, and supplier performance, and drive innovation.',
  },
]
